<template>
  <v-dialog v-model="dialogVisible" persistent max-width="750px">
    <v-card>
      <v-card-title>
        Neues Objekt erstellen
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-select
          :items="$store.getters.writePermissionStructure"
          :loading="$store.state.rights.isLoading"
          v-model="structure"
          label="Objekt Typ"
          :rules="[rules.required]"
          required
          item-text="right"
          return-object
        ></v-select>
      </v-card-text>
      <v-card-text v-if="structure.category == 'Ladeinfrastruktur'">
        <Ladeinfrastruktur :structure="structure" @closeDialog="closeDialog" />
      </v-card-text>
      <v-card-text v-if="structure.category == 'Stempelgeräte'">
        <Stempelgeräte :structure="structure" @closeDialog="closeDialog" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Stempelgeräte from "./stempelgeräte.vue";
import Ladeinfrastruktur from "./ladeinfrastruktur.vue";

export default {
  components: { Stempelgeräte, Ladeinfrastruktur },
  props: {
    active: Number,
  },
  data() {
    return {
      dialogVisible: false,
      structure: {
        category: "",
      },
      rules: {
        required: (value) => !!value || "Required",
      },
    };
  },

  methods: {
    closeDialog() {
      this.dialogVisible = false;
    },
  },

  watch: {
    active() {
      this.dialogVisible = true;
    },
  },
};
</script>
