<template>
  <v-container>
    <v-data-table
      class="elevation-1 mt-5"
      loading-text="Benutzer werden geladen..."
      :footer-props="footerProps"
      :headers="headers"
      :header-props="headerProps"
      :items="$store.state.users.list"
      :loading="$store.state.users.isLoading"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-dialog v-model="dialog" max-width="500px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Neue Benutzer:in anlegen
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5"> {{ formTitle }} </span>
              </v-card-title>
              <v-card-text v-if="editedIndex === -1">
                <v-autocomplete
                  v-model="selectedUser"
                  :items="filteredMappUsers"
                  :item-text="
                    (item) =>
                      `${item.firstname} ${item.name} (${item.adaccount}@itvv.org)`
                  "
                  :loading="$store.state.mappUsers.isLoading"
                  no-data-text="Benutzer:in nicht gefunden oder hat bereits Zugriff"
                  return-object
                  label="Benutzer:in auswählen"
                ></v-autocomplete>
              </v-card-text>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.profile.name"
                        label="Vorname"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.profile.family_name"
                        label="Name"
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.profile.aid"
                        label="A Kennung"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                        :items="[true, false]"
                        label="Admin"
                        v-model="editedItem.permissions.admin"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="editedItem.permissions.readPermissions"
                        :items="$store.getters.allReadPermissions"
                        label="Leseberechtigungen"
                        multiple
                        chips
                      >
                        <template #selection="{ item }">
                          <v-chip color="primary">
                            {{ item }}
                          </v-chip>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="editedItem.permissions.writePermissions"
                        :items="$store.getters.allWritePermissions"
                        label="Schreibberechtigungen"
                        multiple
                        chips
                      >
                        <template #selection="{ item }">
                          <v-chip color="primary">
                            {{ item }}
                          </v-chip>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close"> Abbrechen </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="save"
                  :disabled="isNotValidForm"
                >
                  Speichern
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5 word-break">
                Möchtest du {{ editedItem.profile.name }} {{ editedItem.profile.family_name }} wirklich löschen?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDelete">
                  Abbrechen
                </v-btn>
                <v-btn color="primary" text @click="deleteItemConfirm">
                  OK
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="backAction">
            Zurück
          </v-btn>
        </v-toolbar>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details
          class="pa-4"
        ></v-text-field>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:[`item.permissions.readPermissions`]="{ item }">
        <v-chip-group column>
          <v-chip
            color="primary"
            v-for="permission in item.permissions.readPermissions"
            :key="permission"
          >
            {{ permission }}
          </v-chip>
        </v-chip-group>
      </template>
      <template v-slot:[`item.permissions.writePermissions`]="{ item }">
        <v-chip-group column>
          <v-chip
            color="primary"
            v-for="permission in item.permissions.writePermissions"
            :key="permission"
          >
            {{ permission }}
          </v-chip>
        </v-chip-group>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      selectedUser: {
        adaccount: "",
        email: "",
        firstname: "",
        name: "",
        persid: "",
      },
      search: "",
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Vorname",
          value: "profile.name",
        },
        { text: "Name", value: "profile.family_name" },
        { text: "A Kennung", value: "profile.aid" },
        { text: "Admin", value: "permissions.admin" },
        { text: "Leseberechtigungen", value: "permissions.readPermissions" },
        {
          text: "Schreibberechtigungen",
          value: "permissions.writePermissions",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      headerProps: {
        sortByText: "Sortierung",
      },
      footerProps: {
        itemsPerPageText: "Benutzer pro Seite",
      },
      editedIndex: -1,
      editedItem: {
        permissions: {
          admin: false,
          readPermissions: [],
          writePermissions: [],
        },
        profile: {
          aid: "",
          email: "",
          email_verified: false,
          family_name: "",
          name: "",
          persid: "",
        },
      },
      defaultItem: {
        permissions: {
          admin: false,
          readPermissions: [],
          writePermissions: [],
        },
        profile: {
          aid: "",
          email: "",
          email_verified: false,
          family_name: "",
          name: "",
          persid: "",
        },
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Neue:n Benutzer:in anlegen"
        : "Benutzer:in bearbeiten";
    },

    isNotValidForm() {
      if (
        this.editedItem.profile.name === "" ||
        this.editedItem.profile.family_name === "" ||
        this.editedItem.profile.aid === ""
      ) {
        return true;
      }
      return false;
    },

    filteredMappUsers() {
      return this.$store.state.mappUsers.list.filter((mappUser) => {
        return !this.$store.state.users.list.some(
          (user) => mappUser.id.toLowerCase() == user.id.toLowerCase()
        );
      });
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    selectedUser(val) {
      this.editedItem.profile.aid = `${val.adaccount}@itvv.org`;
      this.editedItem.profile.email =
        val.email !== "" ? val.email.toLowerCase() : "";
      this.editedItem.profile.email_verified = val.email !== "" ? true : false;
      this.editedItem.profile.family_name = val.name;
      this.editedItem.profile.name = val.firstname;
      this.editedItem.profile.persid = val.persid;
    },
  },

  created() {
    this.$store.dispatch("attachUsersOnSnapshot");
    this.$store.dispatch("attachMappUsersOnSnapshot");
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.$store.state.users.list.findIndex(
        (listItem) => item.id == listItem.id
      );
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.$store.state.users.list.findIndex(
        (listItem) => listItem.id == item.id
      );
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$store.dispatch("deleteUser", { id: this.editedItem.id });
      this.dialogDelete = false;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        this.$store.dispatch("updateUser", {
          id: this.editedItem.id,
          user: this.editedItem,
        });
      } else {
        this.$store.dispatch("updateUser", {
          id: this.editedItem.profile.aid,
          user: this.editedItem,
        });
      }
      this.close();
    },

    backAction() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.word-break {
  word-break: normal;
}
</style>
