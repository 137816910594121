<template>
  <v-dialog v-model="dialogVisible" persistent max-width="750px">
    <v-card>
      <v-card-title class="headline">
        Möchten Sie das Objekt {{ objectLabel }} wirklich löschen?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialog"> Abbrechen </v-btn>
        <v-btn color="primary" text @click="deleteObject"> Löschen </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    active: Number,
    object: Object,
  },

  data() {
    return {
      dialogVisible: false,
    };
  },

  watch: {
    active() {
      this.dialogVisible = true;
    },
  },

  computed: {
    objectLabel() {
      if (this.object?.label) {
        return this.object?.label;
      }
      return "";
    },
  },

  methods: {
    closeDialog() {
      this.dialogVisible = false;
    },

    deleteObject() {
      const firebaseCollectionPath =
        this.$store.getters.writePermissionStructure.find(
          (structure) => structure.label == this.object.mainCategory
        ).firebaseCollectionPath;

      this.$store.dispatch("deleteObject", {
        docid: this.object.docid,
        firebaseCollectionPath,
      });
      this.closeDialog();
    },
  },
};
</script>
